import { graphql, StaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { Component } from 'react';
import FilmCard from '../container/work-card';

const query = graphql`
query GetLatestFilms {
  allStrapiFilms(limit: 6) {
		edges {
      node {
				Title
        OpenURL
        Category
        Description
        Thumbnail {
          childImageSharp {
            gatsbyImageData(
              height:500
              placeholder: BLURRED
              formats: [AUTO, WEBP, PNG]
              )
          }
        }
      }
    }
  }
}

`
export default class LatestFilms extends Component {
  getLink(pre, suf) {
    return pre + suf;
  }
  render() {
    return (
      <div className="d-grid grid-cols-3 grid-gap grid-center">
        <StaticQuery
        query={query}
        render={
          (d) => {
            return d.allStrapiFilms.edges.map(row => {
              const data = row.node
              return (<FilmCard
                link={data.OpenURL}
                title={data.Title}
                description={data.Description}
                image={getImage(data.Thumbnail)}
                tag={data.Category.replace('_', ' ')} />)
            })
            // 

          }
        } />
      </div>
    )
  }
}
