import React, { Component } from 'react'
import FeaturedFilm from '../presentation/featured-film';
import LatestFilms from '../presentation/latest-films';
import LatestStills from '../presentation/latest-stills';

import '../scss/entry.scss'
import HomePromo from '../presentation/home-promo';
import Page from '../container/page';
import { Helmet } from 'react-helmet';

export default class Home extends Component {
  render() {
    return [
      <Helmet>
        <title>Joshua Carroll | Home</title>
      </Helmet>,
      <Page>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <HomePromo />
        </div>
        <section>
          <div className="container">
              <FeaturedFilm />
          </div>
        </section>
        <br />
        {/* <section>
            <Testimonials>
                <Testimonial title="Client Name" subtitle="Project Name">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ornare, orci ac pretium sollicitudin, eros urna ultricies metus, id commodo sapien ligula ac ipsum...</Testimonial>
            </Testimonials>
        </section> */}
        <section>
          <p className="h2">latest stills</p>
        </section>
        <br />
        <section>
            <div className="container">
              <LatestStills />
            </div>
        </section>

        <section>
          <p className="h2">latest films</p>
        </section>
        <br />
        <section>
            <div className="container">
              <LatestFilms />
            </div>
        </section>
        {/* <br />
        <div className="gradient vh-25" style={{width:'100%'}}>
            <div className="container text-center">
                <p className="h1 text-white">Award Shortlist Nominee</p>
            </div>
        </div> */}
      </Page>
    ]
  }
}
