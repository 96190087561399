import { graphql, StaticQuery } from 'gatsby';
import React from 'react'
import { getImage } from 'gatsby-plugin-image';
import FeaturedCard from '../container/featured-card';

const query = graphql`
query GetFeaturedFilm {
  strapiFilms(Featured: {eq: true}) {
    Title
    Description
    Thumbnail {
			childImageSharp {
				gatsbyImageData(
          width:800
          placeholder: BLURRED
          formats: [AUTO, WEBP, PNG]
          )
      }
    }
    Category
    OpenURL
  }
}

`

export default function FeaturedFilm() {
  return (
    <StaticQuery
      query={query}
      render={
        (d) => {
          const data = d.strapiFilms
          return (
            <FeaturedCard 
            title={data.Title} 
            description={data.Description} 
            image={getImage(data.Thumbnail)} 
            tag={data.Category.replace('_', ' ')} 
            link={data.OpenURL} />
          )
        }
      }
    />
  )
}
