import React, { Component } from 'react';
import proptypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

export default class WorkCard extends Component {
  render() {
    return (
    <div style={{height: '100%'}}>
        {this.props.image ? <a className="h3" href={this.props.link || ''} target="_blank" rel="noopener noreferrer"><GatsbyImage alt={`${this.props.title} thumbnail`} image={this.props.image} /></a> : false}
        <div className={` ${this.props.image ? 'pt-10 pb-10' : ''}`}>
            {this.props.tag ? <p className="highlight" >{this.props.tag}</p> : false}
            {this.props.title ? <p><a className="h3" href={this.props.link || ''} target="_blank" rel="noopener noreferrer">{this.props.title}</a></p> : false}
            {this.props.description ? <p>{this.props.description}</p> : false}
        </div>
    </div>
    )
  }
}

WorkCard.propTypes = {
    title: proptypes.string,
    description: proptypes.string,
    image: proptypes.string,
    tag: proptypes.string,

    link: proptypes.string
}