import React, { Component } from 'react';
import proptypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

export default class FeaturedCard extends Component {
  render() {
    return (
    <div className={`d-grid ${this.props.image ? 'grid-cols-3' : ''}`}>
        {this.props.image ? <a aria-label={`Link to view "${this.props.title}"`} className="grid-column-span-2" rel="noopener noreferrer" href={this.props.link || undefined} target="_blank"><GatsbyImage alt={`${this.props.title}'s Thumbnail`} image={this.props.image} /></a> : false}
        <div className={`grid-column-span-1 ${this.props.image ? 'p-10 pt-0' : ''}`}>
            {this.props.tag ? <p className="highlight">{this.props.tag}</p> : false}
            {this.props.title ? <p className="h3"><a rel="noopener noreferrer" href={this.props.link || undefined} target="_blank">{this.props.title}</a></p> : false}
            {this.props.description ? <p>{this.props.description}</p> : false}
        </div>
    </div>
    )
  }
}

FeaturedCard.propTypes = {
    title: proptypes.string,
    description: proptypes.string,
    image: proptypes.string,
    tag: proptypes.string,

    titleLink: proptypes.string,
    tagLink: proptypes.string
}