import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
// import {fal} from '@fortawesome/pro-light-svg-icons'
// import {far} from '@fortawesome/pro-regular-svg-icons'
// import {fad} from '@fortawesome/pro-duotone-svg-icons'
import {fas} from '@fortawesome/pro-solid-svg-icons'

library.add(fas)

const query = graphql`
{
    strapiHomePromoHeader {
        text
        link_url
        link_icon
        enabled
    }
}
`

export default function HomePromo() {
    return (
        <StaticQuery query={query} render={
            (d) => {
                const data = d.strapiHomePromoHeader
                return (
                    data.enabled ? <a target="__blank" href={data.link_url}><section style={{display: 'flex', flexDirection: 'row', width: 'fit-content', textAlign: 'center', background: '#b79ba9', color: 'white', borderRadius: 10, padding: '0.7em 2em', justifyContent: 'center', alignItems: 'center'}}>
                        <p>
                            {data.text}
                            </p>
                        {/* <p>{JSON.stringify(data.link_icon)}</p> */}
                        { data.link_icon ? <span>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={['fas', data.link_icon]} /></span> : null }

                    </section></a> : null
                )
            }
        } />
        
    )
}
