import React from 'react';
import {graphql,StaticQuery} from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const query = graphql`
  query GetLatestStills {
    allStrapiStillContent(limit: 6) {
      edges {
        node {
          Title
          Description
          Type
          Content {
            publicURL
            childImageSharp {
              gatsbyImageData(
                height: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP, PNG]
              )
            }
          }
        }
      }
    }
  }
`
export default function LatestStills() {
  return (
    <StaticQuery 
    query={query}
    render={data => (
      <div className="d-grid grid-cols-6 grid-gap grid-center">
        {
          data.allStrapiStillContent ? data.allStrapiStillContent.edges.map(e => {
            return (<a href="/stills"><GatsbyImage alt={e.node.Content.Title} image={getImage(e.node.Content)} /></a>);
          }) : null
        }
      </div>
    )}
    />
  )
}
